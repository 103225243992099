import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import axiosClient from "../axiosClient";
import { Class, Subscription } from "../types";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

// TODO: find a way to share these types between frontend and backend
interface Student {
  id: number,
  userId: number,
  formName: string,
  yearGroup: number
}

interface Teacher {
  id: number,
}

interface User {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  username: string;
  type: 'student' | 'teacher';
  student: Student;
  teacher: Teacher;
  subscription: Subscription;
  platform: Platform;
}


interface UserContextType {
  user: User | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
  validateCookie: () => Promise<boolean>;
  enrolments: Class[];
  fetchEnrolments: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode; // Specify ReactNode type for children prop
}

export enum Platform {
  MasterQ = "MasterQ",
  OphthalmologyQ = "OphthalmologyQ"
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [enrolments, setEnrolments] = useState<Class[]>([]);
  const navigate = useNavigate()

  const login = async (username: string, password: string) => {
    try {
      const loginData: {
        isFirstTimeLogin: boolean;
        user: User;
      } = (
        await axiosClient.post("/auth/login", {
          username,
          password,
        })
      ).data;
      setUser(loginData.user);
      return true;
    } catch (err) {
      return false;
    }
  };

  const logout = async () => {
    try {
      await axiosClient.post("/auth/logout");
      window.location.href = process.env.REACT_APP_WP_SITE_URL || `${window.location.href}/login`
    } catch (err) {
      console.log(err);
      toast.error("Logout failed");
    }
    setUser(null);
  };

  const validateCookie = async () => {
    try {
      const validCookie: {
        status: string,
        user: User
      } = (await axiosClient.get("/auth/check-cookie")).data;
      setUser(validCookie.user);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const fetchEnrolments = async () => {
    const studentView = user?.type === 'student'
    if (studentView) {
      // refresh enrolments every time you load dashboard, but keep it in state in case sidebar needs it (multiple places across app)
      const enrolmentsResponse = (await axiosClient.get(`/students/${user.student.id}/enrolments`)).data;
      setEnrolments(enrolmentsResponse);
    } else {
      // perhaps these can be merged into one endpoint
      // only make callout if `enrolments` not currently stored in state
      const classesResponse: Class[] = (await axiosClient.get(`/teachers/${user?.teacher.id}/classes`)).data;
      setEnrolments(classesResponse);
    }
  }

  return (
    <UserContext.Provider
      value={{ user, login, logout, validateCookie, enrolments, fetchEnrolments }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};
