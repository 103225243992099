import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../Button/Button";
import "./CheckoutForm.scss";
import axiosClient from "../../axiosClient";

export default function CheckoutForm(props: {
  subscriptionType: 'monthly' | '6_monthly'
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );
  const [isLoading, setLoading] = useState(false);

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handlePayNowButtonClicked = async () => {
    try {
      // call form validation
      const form = document.querySelector("form") as HTMLFormElement;
      const formValid = form.reportValidity();

      if (!formValid) {
        const at_least_13_years_old_checkbox = document.querySelector('input[name="at_least_13_years_old_check"]') as HTMLInputElement;
        console.log(at_least_13_years_old_checkbox.checked);
        if (!at_least_13_years_old_checkbox.checked) {
          at_least_13_years_old_checkbox.classList.add('required_at_least_13_years_old_checkbox')
        }
        else {
          at_least_13_years_old_checkbox.classList.remove('required_at_least_13_years_old_checkbox')
        }
        return;
      }

      const name = (
        document.querySelector('input[name="name"]') as HTMLInputElement
      ).value.trim();
      const surname = (
        document.querySelector('input[name="surname"]') as HTMLInputElement
      ).value.trim();
      const email = (
        document.querySelector('input[name="email"]') as HTMLInputElement
      ).value.trim();
      const username = (
        document.querySelector('input[name="username"]') as HTMLInputElement
      ).value.trim();
      const password = (
        document.querySelector('input[name="password"]') as HTMLInputElement
      ).value;
      const confirmPassword = (
        document.querySelector('input[name="confirmPassword"]') as HTMLInputElement
      ).value;
      

      // Add extra validation
      if (!name || !surname || !email || !username || !password || !confirmPassword) {
        setErrorMessage("Please ensure all fields have been filled.");
        return;
      }


      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setErrorMessage("Invalid email address");
        return;
      }

      if (username.includes(name) || username.includes(surname) || username.includes(email.split("@")[0])) {
        setErrorMessage("Your username must NOT contain your first name, surname, or the part of your email before the @ sign.");
        return;
      }

      if (!/^[a-zA-Z0-9._-]+$/.test(username)) {
        setErrorMessage("Your username must not have weird characters.");
        return;
      }

      if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match!')
        return;
      }

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoading(true);

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      const response = await axiosClient.post("/payments/create-subscription", {
        name,
        surname,
        email,
        username,
        password,
        subscription_type: props.subscriptionType
      });

      const { type, clientSecret } = await response.data;
      const confirmIntent =
        type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.origin}/dashboard`,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (err: any) {
        if (err?.response?.status == 400) {
          // 400 errors should be safe so surface as they are handled
          handleError({
            message: err.response?.data?.error
          });
        }
        else {
          handleError({
            message: "Payment failed. Please contact support@masterq.co.uk for support"
          });
        }
      return;
    }
  };

  return (
    <div id="CheckoutForm">
      <PaymentElement />
      <Button
        text={isLoading ? "Processing ... " : "Pay now"}
        disabled={isLoading || !stripe || !elements}
        onClick={handlePayNowButtonClicked}
      ></Button>
      {/* Show any error or success messages */}
      {errorMessage && <div id="payment-error-message">{errorMessage}</div>}
    </div>
  );
}