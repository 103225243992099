import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import "./AddQuestionsPage.scss";
import Button from "../../components/Button/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component/dist/src/DataTable/types";
import Table from "../../components/Table/Table";
import DragAndDropFileUploader from "../../components/DragAndDropFileUploader/DragAndDropFileUploader";
import axiosClient from "../../axiosClient";
import Latex from "react-latex-next";
import toast from "react-hot-toast";

const AddQuestionsPage = (props: any) => {
  // drag state
  const [questions, setQuestions] = useState<any[]>([]);
  const navigate = useNavigate();

  const columns: TableColumn<any>[] = [
    {
      id: "question",
      name: "Question",
      selector: (row: any) => row.renderedQuestion,
      width: "600px",
    },
    {
      id: "subject",
      name: "Subject",
      selector: (row: any) => row.subject,
    },
    {
      id: "topic",
      name: "Topic",
      selector: (row: any) => row.topic,
    },
    {
      id: "board",
      name: "Boards",
      selector: (row: any) => row.board,
    },
  ];

  const onCancelButtonClick = () => {
    setQuestions([]);
  };

  const onConfirmButtonClick = async () => {
    try {
      await axiosClient.post("/questions", questions);
      toast.success("Success!");
      navigate("/dashboard");
    } catch (err) {
      toast.error("Error occurred when persiting questions. Please contact support.");
      console.error(err);
      navigate("/");
    }
  };

  const delimeters = [
    {
      // NOTE: `display` is by default `true` for the '$$' delimeter option
      // out the box from the react-latex-next package. However, I've set it
      // to `false` to ensure it doesn't render the equation in the centre
      // of the parent. Instead, `false` ensures the equation is rendered
      // in-line with the text. I should also point out that I could have just
      // used the single '$' delimeter option, where all questions with equations
      // have the equation wrapped with a single '$' either side, but I thought
      // this was risky if by accident a question has a $ sign in the question
      // text itself. In such a case, we might not realise, and the question
      // would render incorrectly. Thus, I have opted for the '$$' approach,
      // despite it meaning that we have to manually define the delimeters in
      // our implementation (which wouldn't have been the case otherwise).
      // See: https://www.npmjs.com/package/react-latex-next
      display: false,
      left: "$$",
      right: "$$",
    },
  ];

  return (
    <div className="page-wrapper" id="AddQuestionsPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title="Add Questions" />
        <main>
          <div className="white-box">
            {questions.length > 0 ? (
              <>
                <div id="review-questions-header-wrapper">
                  <h2>Review questions</h2>
                  <div id="review-questions-button-wrapper">
                    <Button
                      text="Cancel"
                      type="inverse"
                      onClick={onCancelButtonClick}
                    />
                    <Button text="Confirm" onClick={onConfirmButtonClick} />
                  </div>
                </div>

                <p>
                  Please review the questions to be added, and if happy, please
                  click on the 'Confirm' button. If you are unhappy, please
                  click on 'Cancel' to try again.
                </p>

                <Table columns={columns} data={questions} />
              </>
            ) : (
              <>
                <h2>Questions csv file upload</h2>
                <p>Please upload a csv file containing all questions</p>
                <p>
                  Csv files must contain the following columns in the following
                  order <strong>(and match them exacty)</strong>:{" "}
                  <code>Title, First name, Surname, Is Admin, Class</code>
                </p>
                <p>
                  Please download this template csv file and then update to
                  include all your questions.
                </p>
                <Button text="Download template.csv" type="inverse" />

                <DragAndDropFileUploader
                  accept=".csv"
                  setData={(uploadedQuestions) => {
                    setQuestions(uploadedQuestions.map(uploadedQuestion => {
                      return {
                        ...uploadedQuestion,
                        renderedQuestion: (
                          <p className="question-text-wrapper">
                            <Latex delimiters={delimeters}>
                              {uploadedQuestion.question}
                            </Latex>
                          </p>
                        )
                      }
                    }))
                  } }
                  expectedColumnTitles={[
                    "question",
                    "answer_a",
                    "answer_b",
                    "answer_c",
                    "answer_d",
                    "answer_e",
                    "correct_answer",
                    "explanation",
                    "subject",
                    "topic",
                    "level",
                    "board",
                    "filename",
                    "has_image",
                    "has_maths",
                    "question_image",
                    "answer_a_image",
                    "answer_b_image",
                    "answer_c_image",
                    "answer_d_image",
                    "explanation_image",
                    "source"
                  ]}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddQuestionsPage;
